
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import { handleAnonymizeName } from '@/composables/DataAnonymization';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { IStoreProfileData } from './ListPage.vue';
import { useCompanyStore } from '@/store/CompanyStore';
import { useFilterMainStore } from '@/store/FilterMainStore';

interface IStoreProfileResponse {
  data: {
    error: boolean,
    message: string,
    data: IStoreProfileData
    errorCode: string
  },
  status: number
}

function checkEmptyFields(obj: IStoreProfileData): boolean {
  for (let key in obj) {
    if (obj[key] === "") {
      return true;
    }
  }
  return false;
}

export default defineComponent({
  name: "StoreProfilePut",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  },
  setup() {
    const route = useRoute();
    const loaderStore = useLoaderStore();
    const queryObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data:  null | IStoreProfileData
    }>({
      isLoading: false,
      noError: false,
      data: null
    })
    const { id } = route.params;
    const { showTimeAlert } = useAlert()
    const isRequiredField = ref(false)
    const companyStore = useCompanyStore()
    const filterMainStore = useFilterMainStore()

    // Functions
    async function onSubmitForm() {
      isRequiredField.value = false
      const { data } = queryObject.value
      if(!data)
        return
      if(checkEmptyFields(data))
        return isRequiredField.value = true
      loaderStore.open()
      try {
        const { data } = queryObject.value
        if(data) {
          const { id, name, points_value } = data
          const result: IStoreProfileResponse = await axios.put(`/api/putProfile`, { id, name, points_value })
          showTimeAlert("Atualizado com sucesso!")
          return router.push("/perfis-das-lojas")
        }
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
        loaderStore.close()
      }
    }

    function handleInitFilterModal() {
      if(!companyStore.getId) {
        filterMainStore.hideModal()
        filterMainStore.showModal()
      }
    }

    // Lifecycles
    onMounted(async () => {
      handleInitFilterModal()
      loaderStore.open()
      try {
        const result: IStoreProfileResponse = await axios.get(`/api/getProfileView/${id}`)
        const { data } = result
          return queryObject.value.data = data.data
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
      }
    })

    return {
      queryObject,
      isRequiredField,
      onSubmitForm,
      handleAnonymizeName
    }
  }
})
